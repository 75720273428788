import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';
import _ from "lodash";

export default {
  name: 'category-select',
  data() {
    return {
      showAll: true,
      open: false,
      checked: [],
      selectedFilters: [],
      limit: 5
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: []
    },
    slug: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  components: {
    VueSlideToggle
  },
  watch: {
    '$route'() {
      // const query = this.$route.query;
      // if (query[this.slug]) {
      //   let currentObj = _.find(this.filterCategories, ['slug', this.slug]);
      //   let selected = [];
      //   let currentQuery = query[this.slug];
      //
      //   if (!Array.isArray(currentQuery)) {
      //     currentQuery = [];
      //     currentQuery.push(query[this.slug]);
      //   }
      //
      //   currentObj.values.forEach(e => {
      //     currentQuery.forEach(t => {
      //       if (e.slug === t) {
      //         selected.push(e);
      //       }
      //     });
      //   });
      //   this.checked = selected;
      // } else {
      //   this.checked = [];
      // }
    }
  },
  created() {
    // if (this.filterCategories) {
    //   const query = this.$route.query;
    //   if (query.hasOwnProperty(this.slug)) {
    //     this.open = true;
    //     let currentObj = _.find(this.filterCategories, ['slug', this.slug]);
    //     let selected = [];
    //     let currentQuery = query[this.slug];
    //
    //     if (!Array.isArray(currentQuery)) {
    //       currentQuery = [];
    //       currentQuery.push(query[this.slug]);
    //     }
    //
    //     currentObj.values.forEach(e => {
    //       currentQuery.forEach(t => {
    //         if (e.slug === t) {
    //           selected.push(e);
    //         }
    //       });
    //     });
    //
    //     this.checked = selected;
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      // filterCategories: 'setting/filterCategories',
    }),
    query() {
      // this.selectedFilters = [];
      // if (this.checked) {
      //   this.checked.forEach(e => {
      //     this.selectedFilters.push(e.slug)
      //   });
      // }
      // return {
      //   [this.slug]: this.selectedFilters
      // }
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      // fetchList: `category/GET_FILTER_PRODUCTS_LIST`
    }),
    select() {
      // this.$router.push({
      //   name: 'categories',
      //   params: {slug: this.$route.params.slug},
      //   query: Object.assign({}, this.$route.query, this.query)
      // }).catch(() => {
      //   console.log()
      // });
      // this.sendRequest();
    },
    sendRequest: _.debounce(function () {
      // const obj = {};
      // for (const i in this.$route.query) {
      //   if (this.$route.query[i].length) {
      //     obj[i] = this.$route.query[i]
      //   }
      // }
      // this.fetchList({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all', data: {'attributes': obj}});
    }, 800),
  }
}
