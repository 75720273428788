import {mapGetters} from "vuex";

export default {
  props: ['sub','title'],
  name: 'subcategories',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      sublistLoading: 'category/listLoading',

    }),
  },
  methods: {

    reset() {
    }
  },

}
