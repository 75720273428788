import CategoryFilter from './components/filter/index.vue';
import {mapGetters, mapActions, mapMutations} from "vuex";
import CategorySelect from '../categories/components/select/index.vue';
import VueAdsPagination, {VueAdsPageButton} from 'vue-ads-pagination';
import mainSelect from '@/components/main-select/index.vue';
import product from '@/components/product/index.vue';
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'
import subcategories from './components/subcategories/index.vue'

export default {
  name: 'categories',
  data() {
    return {
      filterIconRotate: false,
      page: 0,
      selectedFilters: {
        name: 'По Цене',
        key: 'sort=priceDown'
      },
      types: [
        {
          name: 'По Цене',
          key: 'sort=priceDown'
        },
        {
          name: 'По алфавіту',
          key: 'sort=alphabeticalDown'
        }
      ],


      categoryMock: [
        {
          title: 'Возраст',
          category: [
            {id: 0, title: 'Универсальный'},
            {id: 1, title: '20+'},
            {id: 2, title: '30+'},
            {id: 3, title: '35+'},
            {id: 4, title: '40+'}
          ]
        },
        {title: 'Тип средства'},
        {title: 'Тип кожи'},
        {title: 'Целевой уход'},
      ]

    }
  },
  components: {
    CategoryFilter,
    CategorySelect,
    customBreadcrumbs,
    VueAdsPagination,
    VueAdsPageButton,
    mainSelect,
    product,
    subcategories
  },
  watch: {
    skip() {
      if (this.skip === 0) {
        this.page = 0;
      }
    },
    '$route'() {
      if (this.$route.name !== 'catalog') {
        this.fetchList({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all'});
        this.fetchfilterCategories({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all'});
      }else {
        this.fetchAllProducts()
      }
      if (this.isAuthenticated) {
        this.fetchFavoriteList().then(() => {
          // console.log(this.whichList);
        })
      }
    },
    'selectedFilters.key'(elem) {
      let slug = this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all';
      let data = this.listFilter
      switch (elem) {
        case 'sort=priceDown':
          this.fetchFilters({
            filter: elem,
            slug: slug,
            data: data
          });
          break;
        case 'sort=priceUp':
          this.fetchFilters({
            filter: elem,
            slug: slug,
            data: data
          });
          break;
        case 'sort=alphabeticalDown':
          this.fetchFilters({
            filter: elem,
            slug: slug,
            data: data
          });
          break;
        case 'sort=alphabeticalUp':
          this.fetchFilters({
            filter: elem,
            slug: slug,
            data: data
          });
          break;
      }
    }
  },
  computed: {
    ...mapGetters({
      list: 'category/list',
      breadcrumbs: 'category/breadcrumbs',
      listLoading: 'category/listLoading',
      listFilter: 'category/listFilter',
      listLength: 'category/listLength',
      limit: 'category/limit',
      filterCategories: 'setting/filterCategories',
      filterCategoriesLoading: 'setting/filterCategoriesLoading',
      showFilter: 'system/showFilter',
      isAuthenticated: `auth/isAuthenticated`
    }),
  },
  created() {
    if (this.$route.name !== 'catalog') {
      this.fetchList({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all'})
        .then(() => {
        });
      this.fetchfilterCategories({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all'})
        .then(() => {
        });
    }else{
      this.fetchAllProducts()
    }
  },
  methods: {

    ...mapActions({
      fetchList: `category/GET_PRODUCTS_LIST`,
      fetchFilters: `category/GET_FILTER_PRODUCTS_LIST`,
      fetchfilterCategories: 'setting/GET_CATEGORY_FILTERS',
      fetchFavoriteList: `favorites/GET_FAVORITES_LIST`,
      fetchAllProducts: 'category/GET_ALL_PRODUCTS_LIST'
    }),
    ...mapMutations({
      changePage: `category/PRODUCTS_CHANGE_PAGE`,
      setCheckedFilters: `category/SET_CHECKED_FILTERS`,
    }),
    addToBasket(product) {
      this.addBasket({
        select_count: this.count,
        select_price: product.discount_price ? product.discount_price : product.price,
        ...product
      });
      this.$toasted.success(this.$t('successAddBasket'));
    },
    changeSort() {
      let elem = this.selectedFilters.key
      switch (elem) {
        case 'sort=priceDown':
          this.selectedFilters.key = 'sort=priceUp'
          break;
        case 'sort=priceUp':
          this.selectedFilters.key = 'sort=priceDown'
          break;
        case 'sort=alphabeticalDown':
          this.selectedFilters.key = 'sort=alphabeticalUp'
          break;
        case 'sort=alphabeticalUp':
          this.selectedFilters.key = 'sort=alphabeticalDown'
          break;
      }
      this.filterIconRotate = !this.filterIconRotate
    },
    changePageLocal(page) {
      this.page = page;
      this.changePage(page + 1);
      this.fetchList({categorySlug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all'});
      // this.fetchList();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    resizeWindow() {
      // if (window.innerWidth < 1251) {
      //     this.changeOpenFilter(false)
      // } else {
      //     this.changeOpenFilter(true)
      // }
    },

    reset() {
      this.$router.push({
        name: 'categories',
        params: {slug: this.$route.params.slug}
      });
      this.setCheckedFilters({})
      this.fetchList({categorySlug: this.$route.params.categorySlug});
      Object.keys(this.$refs).forEach(el => {
        this.$refs[el][0].cleared()
      })
    }
  }
}
