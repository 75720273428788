import {mapGetters, mapActions, mapMutations} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';
import CategorySelect from '../select/index.vue';
import _ from "lodash";

export default {
    props:['item'],
    name: 'category-filter',
    data(){
        return {
            openItem: false,
            selectedFilters: [],
            checked: [],
            check:true
        }
    },
    components: {
        CategorySelect,
        VueSlideToggle
    },
    created() {
    },
    watch: {
        '$route'() {
            this.updateRouter();
        },
        'checked'(){
            let arr = {}
            arr[this.item.key] = this.checked
            this.setCheckedFilters(arr)
            let checkedFilters = this.fetchCheckedFilters
            let keys = Object.keys(checkedFilters);

            const parsedobj = JSON.parse(JSON.stringify(checkedFilters))
            let values =[]
            let library ={}
            library['filters']={}
           keys.forEach((key, index) => {
                parsedobj[key].forEach((item)=>{
                    values.push(item.key)
                })
               library['filters'][key] = values
               values=[]
            });
            this.changelistFilter(library)
            this.fetchFiltersList({
                slug: this.$route.params.categorySlug ? this.$route.params.categorySlug : 'all',
                data: library
            })
        }
    },
    computed: {
        ...mapGetters({

            fetchCheckedFilters: `category/checkedFilters`,
            filterCategories: 'setting/filterCategories',
            filterCategoriesLoading: 'setting/filterCategoriesLoading',
            // showFilter: 'system/showFilter',
        }),
    },
    methods: {
          cleared(){
            this.checked = []
            this.openItem = false
          },
        ...mapActions({
            fetchFilters: `setting/GET_CATEGORY_FILTERS`,
            fetchList: `category/GET_FILTER_PRODUCTS_LIST`,
            fetchFiltersList: `category/GET_FILTRED_PRODUCTS_LIST`,
        }),
        ...mapMutations({
            setCheckedFilters: `category/SET_CHECKED_FILTERS`,
            // removeFilterItem: `category/REMOVE_FILTER_ITEM_IN_SEARCH`,
            // changeOpenFilter: `system/CHANGE_OPEN_FILTER`,
            changelistFilter: `category/SET_FILTER_PRODUCTS_LIST`,
        }),
        updateRouter(){
            this.selectedFilters = arr;
        }
    }
}
